<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 业务内容 -->

          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 0px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
            >
              <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="标段类型" prop="bidType">
                    <el-radio-group v-model="form.bidType">
                      <el-radio :label="0">清扫保洁</el-radio>
                      <el-radio :label="1">垃圾清运</el-radio>
                      <el-radio :label="2">一体化</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="服务范围" prop="serviceScope">
                    <el-checkbox-group v-model="form.serviceScope">
                      <el-checkbox :label="0">垃圾清运</el-checkbox>
                      <el-checkbox :label="1">道路清扫保洁</el-checkbox>
                      <el-checkbox :label="2">公厕管理</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="标段编号" prop="bidNum">
                    <el-input v-model="form.bidNum" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="标段名称" prop="bidName">
                    <el-input v-model="form.bidName" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <!-- 提示文字 -->
                  <p class="tips">提示：标段编号基本格式为“年份”+“行政区”+“标段名称简称首字母”</p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="主管单位" prop="bidManageUnit">
                    <el-input v-model="form.bidManageUnit" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="监管单位" prop="bidSuperviseUnit">
                    <el-input v-model="form.bidSuperviseUnit" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="养护企业">
                    <b>{{form.bidMaintainUnit}}</b>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="所属项目组" prop="projectGroupId">
                    <el-input v-model="form.projectGroupId" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="项目经理" prop="bidPm">
                    <el-input v-model="form.bidPm" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="项目经理联系方式" prop="bidPmPhone">
                    <el-input v-model="form.bidPmPhone" placeholder="请输入" maxlength="11"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="合同面积(m²)" prop="bidContractArea">
                    <el-input
                      v-model.number="form.bidContractArea"
                      type="number"
                      placeholder="请输入"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="合同要求人数" prop="contractPeopleNum">
                    <el-input
                      v-model.number="form.contractPeopleNum"
                      type="number"
                      placeholder="请输入"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="合同要求车辆数" prop="contractCarNum">
                    <el-input
                      v-model.number="form.contractCarNum"
                      type="number"
                      placeholder="请输入"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="要求配置人数" prop="demandPeopleNum">
                    <el-input
                      v-model.number="form.demandPeopleNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="要求配置车辆数" prop="demandCarNum">
                    <el-input
                      v-model.number="form.demandCarNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配设备数" prop="demandDeviceNum">
                    <el-input
                      v-model.number="form.demandDeviceNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="机械化作业长度(m)" prop="mechanizedOperationLen">
                    <el-input
                      v-model.number="form.mechanizedOperationLen"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="实际多边形面积(m²)" prop="bidRealArea">
                    <el-input
                      v-model.number="form.bidRealArea"
                      placeholder="0"
                      type="number"
                      maxlength="20"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配安全生产管理人数" prop="safeManagerNum">
                    <el-input
                      v-model.number="form.safeManagerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配现场管理人数" prop="spotManagerNum">
                    <el-input
                      v-model.number="form.spotManagerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配现场作业人数" prop="spotWorkerNum">
                    <el-input
                      v-model.number="form.spotWorkerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配组长人数" prop="teamManagerNum">
                    <el-input
                      v-model.number="form.teamManagerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配机扫车数量" prop="sweeperNum">
                    <el-input
                      v-model.number="form.sweeperNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配洒水车数量" prop="sprinklerNum">
                    <el-input
                      v-model.number="form.sprinklerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配冲洗车数量" prop="washerNum">
                    <el-input
                      v-model.number="form.washerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配路面养护车数量" prop="careNum">
                    <el-input
                      v-model.number="form.careNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应机扫作业总里程(m)" prop="sweeperMileage">
                    <el-input
                      v-model.number="form.sweeperMileage"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应洒水作业总里程(m)" prop="sprinklerMileage">
                    <el-input
                      v-model.number="form.sprinklerMileage"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配平板车数量" prop="flatCarNum">
                    <el-input
                      v-model.number="form.flatCarNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配勾臂车数量" prop="armCarNum">
                    <el-input
                      v-model.number="form.armCarNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配桶装垃圾运输车数量" prop="bucketTruckNum">
                    <el-input
                      v-model.number="form.bucketTruckNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配大件垃圾运输车数量" prop="bigTruckNum">
                    <el-input
                      v-model.number="form.bigTruckNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配转运站操作员数量" prop="transferOperatorNum">
                    <el-input
                      v-model.number="form.transferOperatorNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配垃圾压缩箱数量" prop="garbageCompactorNum">
                    <el-input
                      v-model.number="form.garbageCompactorNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配公厕保洁员数量" prop="wcCleanerNum">
                    <el-input
                      v-model.number="form.wcCleanerNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="应配水电工数量" prop="plumberNum">
                    <el-input
                      v-model.number="form.plumberNum"
                      placeholder="请输入"
                      type="number"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="标段地址" prop="bidAddress">
                    <el-input v-model="form.bidAddress" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="项目办公室地址" prop="bidOffice">
                    <el-input v-model="form.bidOffice" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map-polygon v-model="mapData" :accuracy="6" @change="onMapChange"></ayl-map-polygon>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // const vm = this;
    return {
      debug: 0,
      nav: [{ name: "新增标段" }],
      submitLoading: false,
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如  [[118,36],[117,32],[116,113]]
        scope: null //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      // checkList: ["垃圾清运"],
      form: {
        bidType: null, //标段类型
        serviceScope: [], //服务范围
        bidNum: null, //标段编号
        bidName: null, //标段名称
        bidCoordinateScope: null, //标段区域范围
        bidManageUnit: null, //主管单位
        bidSuperviseUnit: null, //监管单位
        bidMaintainUnit: null, //养护企业
        projectGroupId: null, //所属项目组
        bidPm: null, //项目经理
        bidPmPhone: null, //项目经理联系方式
        bidContractArea: null, //合同面积
        contractPeopleNum: null, //合同要求人数
        contractCarNum: null, //合同要求车辆数
        demandPeopleNum: null, //要求配置人数
        demandCarNum: null, //要求配置车辆数
        demandDeviceNum: null, //应配设备数
        mechanizedOperationLen: null, //机械化作业长度
        bidRealArea: null, //实际多边形面积
        safeManagerNum: null, //应配安全生产管理人数
        spotManagerNum: null, //应配现场管理人数
        spotWorkerNum: null, //应配现场作业人数
        teamManagerNum: null, //应配组长人数
        sweeperNum: null, //应配机扫车数量
        sprinklerNum: null, //应配洒水车数量
        washerNum: null, //应配冲洗车数量
        careNum: null, //应配路面养护车数量
        sweeperMileage: null, //应机扫作业总里程
        sprinklerMileage: null, //应洒水作业总里程
        flatCarNum: null, //应配平板车数量
        armCarNum: null, //应配勾臂车数量
        bucketTruckNum: null, //应配桶装垃圾运输车数量
        bigTruckNum: null, //应配大件垃圾运输车数量
        transferOperatorNum: null, //应配转运站操作员数量
        garbageCompactorNum: null, //应配垃圾压缩箱数量
        wcCleanerNum: null, //应配公厕保洁员数量
        plumberNum: null, //应配水电工数量
        bidAddress: null, //标段地址
        bidOffice: null //项目办公室地址
      },
      rules: {
        bidNum: {
          required: true,
          message: "请输入标段编号",
          trigger: "blur"
        },
        bidName: {
          required: true,
          message: "请输入标段名称",
          trigger: "blur"
        },
        bidManageUnit: {
          required: true,
          message: "请输入主管单位",
          trigger: "blur"
        },
        bidSuperviseUnit: {
          required: true,
          message: "请输入监管单位",
          trigger: "blur"
        },
        bidMaintainUnit: {
          required: true,
          message: "养护企业不能为空",
          trigger: "blur"
        },
        projectGroupId: {
          required: true,
          message: "所属项目组",
          trigger: "blur"
        },
        bidContractArea: {
          required: true,
          message: "请输入合同面积",
          trigger: "blur"
        },
        contractPeopleNum: {
          required: true,
          message: "请输入合同要求人数",
          trigger: "blur"
        },
        contractCarNum: {
          required: true,
          message: "请输入合同要求车辆数",
          trigger: "blur"
        },
        demandPeopleNum: {
          required: true,
          message: "请输入要求配置人数",
          trigger: "blur"
        },
        demandCarNum: {
          required: true,
          message: "请输入要求配置车辆数",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    onMapChange(mapData) {
      if (mapData) this.form.bidRealArea = "" + mapData.polygonArea;
    },
    async submit() {
      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "bidContractArea":
            case "contractPeopleNum":
            case "contractCarNum":
            case "demandPeopleNum":
            case "demandCarNum":
            case "demandDeviceNum":
            case "mechanizedOperationLen":
            case "safeManagerNum":
            case "spotManagerNum":
            case "spotWorkerNum":
            case "teamManagerNum":
            case "sweeperNum":
            case "sprinklerNum":
            case "washerNum":
            case "careNum":
            case "sweeperMileage":
            case "sprinklerMileage":
            case "bucketTruckNum":
            case "bigTruckNum":
            case "transferOperatorNum":
            case "garbageCompactorNum":
            case "wcCleanerNum":
            case "plumberNum":
            case "armCarNum":
            case "flatCarNum":
              if (d[key] == "") {
                d[key] = null;
              } else if (typeof d[key] == "string") {
                d[key] = d[key] / 1;
              }
              break;
          }
        }
        //补字段
        // if (self.mapData) {
        //   if (self.mapData.lnglat)
        //     d["transferCoordinateScope"] = JSON.stringify(self.mapData.lnglat);
        //   d["isDrawn"] = self.mapData.lnglat ? 1 : 0;
        // }
        // console.log(d);
        return d;
      }

      let params = {
        bidBaseInfo: _fixData(this.form)
      };
      if (this.mapData.polygonScope) {
        params.bidBaseInfo.bidCoordinateScope = JSON.stringify(
          this.mapData.polygonScope
        );
      }
      await this.$api_hw.districtInfo_bidManageAdd(params);
    },
    //提交表单
    async onSubmit(isGoNext) {
      let _this = this;
      if (!this.mapData.polygonScope) {
        this.$message({
          message: "必须绘制出标段所在区域",
          type: "warning"
        });
        return;
      }
      if (_this.submitLoading) return; //防止重复提交
      _this.$refs.form.validate(async valid => {
        if (!valid) return;
        _this.submitLoading = true;
        try {
          await _this.submit();
          _this.$notify({
            title: "成功",
            message: `${_this.nav[0].name}成功`,
            type: "success"
          });
          BUS.$emit(BUSEVENT.REFRESH_DISTRICT_INOF);
          if (isGoNext !== true) {
            this.$refs["form"].resetFields();
            _this.onClose("/district-management/district-info");
            // _this.$router.push("/district-management/district-info");
          } else {
            this.$refs["form"].resetFields();
            this.mapData.polygonScope = null;
            this.getBidMaintainUnit();
          }
        } catch (e) {
          // console.info(e);
        } finally {
          _this.submitLoading = false;
        }
      });
    },

    onSubmitAndNext() {
      this.onSubmit(true);
    },
    onClose(toPath) {
      if (toPath && typeof toPath == "string")
        // for 标签页
        BUS.$emit(BUSEVENT.CLOSE_NAV, [this.$route, toPath]);
      else {
        BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    },

    // 获取所属企业
    async getBidMaintainUnit() {
      await this.$api_hw.districtInfo__getTenantCompanyInfo({}).then(res => {
        this.form.bidMaintainUnit = res.label;
      });
    }
  },
  activated() {
    //重置表单
    this.$refs["form"].resetFields();
    //重置地图
    if (this.mapData) {
      this.mapData.polygonScope = this.mapData.scope = null;
      this.mapData.polygonArea = 0;
    }
    // 获取所属企业
    this.getBidMaintainUnit();
  },
  async mounted() {
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px

  .tips
    margin: 0 0 20px 170px
    margin-bottom: 20px;
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(255,140,26,1)
    line-height: 32px


/deep/ el-form
    display: flex;
    flex-wrap: wrap;
/deep/ .el-form-item
    display: flex;
    width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
    width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
    flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
    text-align: right;
</style>
